import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Menu, X, Brain, Zap, Cpu, Eye,Mic  } from 'lucide-react';


// Add this to your index.css or a separate CSS file
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-gray-900 text-white py-4 fixed w-full z-10">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold font-inter">Rayosense <span className="text-blue-400">AI</span></h1>
        <nav className="hidden md:block">
          <ul className="flex space-x-6">
            <li><a href="#about" className="hover:text-blue-400 transition duration-300">About</a></li>
            <li><a href="#solutions" className="hover:text-blue-400 transition duration-300">Solutions</a></li>
            <li><a href="#industries" className="hover:text-blue-400 transition duration-300">Industries</a></li>
            <li><a href="#contact" className="hover:text-blue-400 transition duration-300">Contact</a></li>
          </ul>
        </nav>
        <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <X /> : <Menu />}
        </button>
      </div>
      {isMenuOpen && (
        <div className="md:hidden mt-4 bg-gray-800">
          <ul className="flex flex-col space-y-2 px-4 py-2">
            <li><a href="#about" className="block py-2 hover:text-blue-400 transition duration-300">About</a></li>
            <li><a href="#solutions" className="block py-2 hover:text-blue-400 transition duration-300">Solutions</a></li>
            <li><a href="#industries" className="block py-2 hover:text-blue-400 transition duration-300">Industries</a></li>
            <li><a href="#contact" className="block py-2 hover:text-blue-400 transition duration-300">Contact</a></li>
          </ul>
        </div>
      )}
    </header>
  );
};

const Hero = () => (
  <section className="bg-gradient-to-r from-blue-600 to-indigo-800 text-white py-32 mt-16">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-5xl font-bold mb-6 font-inter leading-tight">Revolutionize Your Business with AI</h2>
      <p className="text-xl mb-8 max-w-2xl mx-auto">Harness the power of cutting-edge artificial intelligence to transform your operations, boost efficiency, and unlock new possibilities.</p>
      <a href="#contact" className="bg-white text-blue-600 py-3 px-8 rounded-full font-bold text-lg hover:bg-blue-100 transition duration-300 inline-block">Get Started</a>
    </div>
  </section>
);

const SolutionCard = ({ title, description, icon: Icon }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 transform hover:-translate-y-1">
    <div className="text-blue-500 mb-4">
      <Icon size={40} />
    </div>
    <h3 className="text-xl font-semibold mb-2 font-inter">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Solutions = () => {
  const solutions = [
    { 
      title: "AI Vision", 
      description: "Advanced computer vision solutions for retail analytics, smart cities, and industrial automation.", 
      icon: Eye
    },
    { 
      title: "Process Automation", 
      description: "Streamline operations with intelligent AI-powered workflow and task automation.", 
      icon: Zap
    },
    { 
      title: "Cognitive Computing", 
      description: "Harness the power of machine learning and natural language processing for data-driven insights.", 
      icon: Brain
    },
    { 
      title: "Robotic Process Automation", 
      description: "Enhance efficiency with AI-driven robotic process automation for repetitive tasks.", 
      icon: Cpu
    },
    { 
      title: "Voice AI Solutions", 
      description: "Transform customer interactions with advanced speech recognition and natural language understanding.", 
      icon: Mic
    },
  ];

  return (
    <section id="solutions" className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center font-inter">Our Cutting-Edge Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {solutions.map((solution, index) => (
            <SolutionCard key={index} title={solution.title} description={solution.description} icon={solution.icon} />
          ))}
        </div>
      </div>
    </section>
  );
};

const IndustryAccordion = ({ industries }) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <div className="space-y-2 max-w-3xl mx-auto">
      {industries.map((industry, index) => (
        <div key={index} className="border border-gray-200 rounded-lg overflow-hidden">
          <button
            className="flex justify-between items-center w-full p-4 text-left bg-white hover:bg-gray-50 transition duration-300"
            onClick={() => setOpenIndex(openIndex === index ? null : index)}
          >
            <span className="font-semibold text-lg font-inter">{industry.name}</span>
            {openIndex === index ? <ChevronUp className="text-blue-500" /> : <ChevronDown className="text-blue-500" />}
          </button>
          {openIndex === index && (
            <div className="p-4 bg-gray-50">
              <ul className="list-disc pl-5 space-y-2">
                {industry.applications.map((app, appIndex) => (
                  <li key={appIndex} className="text-gray-700">{app}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const Industries = () => {
  const industries = [
    {
      name: "Retail & E-commerce",
      applications: ["Personalized shopping experiences", "Inventory optimization", "Fraud detection", "Visual search technology"]
    },
    {
      name: "Smart Cities",
      applications: ["Intelligent traffic management", "Energy-efficient infrastructure", "Public safety and security", "Smart waste management"]
    },
    {
      name: "Healthcare & Life Sciences",
      applications: ["AI-assisted diagnostics", "Drug discovery acceleration", "Personalized treatment plans", "Remote patient monitoring"]
    },
    {
      name: "Finance & Banking",
      applications: ["Algorithmic trading", "Risk assessment and management", "Fraud detection and prevention", "Chatbots for customer service"]
    },
    {
      name: "Manufacturing & Industry 4.0",
      applications: ["Predictive maintenance", "Quality control automation", "Supply chain optimization", "Digital twin technology"]
    }
  ];

  return (
    <section id="industries" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center font-inter">Industries We Transform</h2>
        <IndustryAccordion industries={industries} />
      </div>
    </section>
  );
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

const [isSubmitting, setIsSubmitting] = useState(false);  // Add new state

const handleSubmit = async (e) => {
  e.preventDefault();
  setError(null);
  setIsSubmitting(true);  // Set to true when submitting starts

  try {
    const response = await fetch('https://script.google.com/macros/s/AKfycby79QaTT9rqIZsBYctEkA69Pyhqc7Uxw4S5IZr8oqjPVKZs7d-mQt_i3vd7BF6VMa7v/exec', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    });

    if (response.type === 'opaque') {
      setIsSubmitted(true);
      setFormData({ name: '', email: '', message: '' });
    } else {
      throw new Error('Failed to submit form');
    }
  } catch (error) {
    setError('There was an error submitting your message. Please try again.');
  }
  setIsSubmitting(false);  // Set to false when submission ends
};

  return (
    <div className="max-w-md mx-auto mt-8">
      {isSubmitted ? (
        <div className="bg-green-100 border border-green-400 text-green-700 px-6 py-4 rounded-lg" role="alert">
          <p className="font-bold mb-2">Thank you for reaching out!</p>
          <p>We've received your message and will get back to you shortly.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="your@email.com"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              placeholder="How can we help you?"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="4"
            ></textarea>
          </div>
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <div className="flex items-center justify-between">
          <button
              className={`bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none transition duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
              type="submit"
              disabled={isSubmitting}  // Disable button while submitting
            >
              {isSubmitting ? 'Submitting...' : 'Send Message'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

const Contact = () => (
  <section id="contact" className="py-20 bg-gray-100">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-4xl font-bold mb-6 font-inter">Let's Build the Future Together</h2>
      <p className="mb-12 text-xl max-w-2xl mx-auto">Ready to harness the power of AI for your business? Get in touch with our experts and discover how Rayosense can transform your operations.</p>
      <ContactForm />
      <div className="mt-12">
        <p className="font-semibold text-lg">Prefer email? Reach us directly at:</p>
        <a href="mailto:aksoumya@rayosense.com" className="text-blue-500 hover:text-blue-700 text-xl font-bold">aksoumya@rayosense.com</a>
      </div>
    </div>
  </section>
);

const Footer = () => (
  <footer className="bg-gray-900 text-white py-12">
    <div className="container mx-auto px-4 text-center">
      <p className="mb-4">&copy; 2024 Rayosense Technologies Private Limited. All rights reserved.</p>
      <div className="flex justify-center space-x-4">
        <a href="#" className="hover:text-blue-400 transition duration-300">Privacy Policy</a>
        <a href="#" className="hover:text-blue-400 transition duration-300">Terms of Service</a>
        <a href="#" className="hover:text-blue-400 transition duration-300">Careers</a>
      </div>
    </div>
  </footer>
);

const App = () => {
  return (
    <div className="min-h-screen flex flex-col font-inter">
      <Header />
      <main className="flex-grow">
        <Hero />
        <Solutions />
        <Industries />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default App;